import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Footer from "../components/footer.js"

const TitleBlock = styled("div")`
    align-items: end;

	h1 {
		margin-bottom: 30px;
		font-size: 2.5rem;
	}

	p {
		font-size: 18px;
    	line-height: 32px;
    	margin-bottom: 1rem;
	}

	ul {
		margin-bottom: 1rem;
		padding-left: 40px;

		li {
			font-size: 18px;
		    margin-bottom: 20px;
		    display: flex;
		    align-items: baseline;

		    &:before {
			    content: '';
			    background: url('../images/correct.svg');
			    min-width: 16px;
			    min-height: 16px;
			    margin-right: 10px;
			    background-position: center;
			    background-size: contain;
			    background-repeat: no-repeat;
			    margin-top: 5px;
			}
		}
	}

	.gatsby-image-wrapper {
		width: fit-content;
		height: 580px;

		@media (max-width: 975.98px) {
			height: auto;
		}

		img {
		    border-radius: 10px;
		}
	}
`

const TextBlock = styled("div")`
	h2 {
		margin-bottom: 30px;
		text-align: center;
    	font-weight: 600;
    	font-size: 2rem;
	}

	p {
		font-size: 18px;
    	line-height: 32px;
	}
`

const Questions = styled("div")`
	.container {
		justify-content: center;
	}

	h2 {
	    text-align: center;
	    font-weight: 600;
	    font-size: 2rem;
	}

	button {
		width: 100%;
	}
`

export default function AboutUs({data}) {
	return (
		<>
			<Seo title="О нас | SleepAdvisor" description="Вся информация о деятельности компании SleepAdvisor и её команде." />
			<Helmet>
				<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru"},{"@type": "ListItem","position": 2,"name": "О нас"}]}`}</script>
			</Helmet>
			<Menu />
			<div className="hero all-page">
				<div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
					<div className="breadcrumb-block">    
						<div className="breadcrumbs">
					        <nav aria-label="breadcrumb">
					        	<ol>
					                <li className="breadcrumb-item"><a href="/">Главная</a></li>
					                <li className="breadcrumb-item active" aria-current="page">О нас</li>
				                </ol>
				            </nav>
					    </div>
					</div>
				</div>
			</div>
			<section className="py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
				<div>
					<TitleBlock className="flex flex-col-reverse lg:flex-row mb-16">
						<StaticImage className="lg:mr-12" src="../images/4549-scaled.jpg" alt="Что мы делаем?" placeholder="none" />
						<div className="font-[merriweather]">
							<h1 className="font-[montserrat]">
								<strong>Что мы делаем?</strong>
							</h1>
							<p>Сайт SleepAdvisor был разработан для предоставления полной и достоверной информации, касающейся темы здорового сна.</p>
							<p>Здесь вы найдете:</p>
							<ul>
								<li>рейтинги, основанные на тестах и отзывах пользователей;</li>
								<li>обзоры на производителей и их продукты;</li>
								<li>статьи, отвечающие на самые разные вопросы, связанные со сном.</li>
							</ul>
							<p>В наших рейтингах и обзорах мы рассматриваем основные характеристики моделей, их преимущества и недостатки, а также оставляем рекомендации для той или иной категории пользователей.</p>
						</div>
					</TitleBlock>
					<TextBlock>
						<div>
							<h2>Зачем использовать наш сервис?</h2>
							<p className="font-[merriweather]">Наша цель, помочь вам при покупке матраса, подсказав, на какие характеристики стоит обратить внимание в первую очередь. Многие пользователи не знают, что их вес, поза во время сна, а так же особенности организма могут влиять на качество сна. Мы собрали актуальные данные, отзывы покупателей и предоставили своим пользователям важную информацию, на которую можно опираться при выборе правильной модели матраса.</p>
						</div>
					</TextBlock>
				</div>
			</section>
			<Questions className="py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
				<div className="flex-col lg:flex-row items-center flex justify-center">
					<StaticImage className="mb-[20px] lg:mb-0 lg:mr-[30px]" src="../images/question.svg" alt="Обратная связь" placeholder="none" />
					<div>
						<h2>У вас есть вопросы?</h2>
						<a href="/contact/" className="mb-[30px] lg:mb-[55px]"><button className="button button_primary mt-5">Обратная связь</button></a>
					</div>
				</div>
			</Questions>
            <Footer />
	    </>
    )
}